@layer components {
	.bin-table-responsive tr {
		@apply block

		lg:table-row;
	}

	.bin-table-responsive td {
		@apply block
		w-full
		py-0

		before:content-[attr(data-label)]
		before:float-left

		lg:table-cell
		lg:w-auto
		lg:py-6;
	}
}
