@layer components {
	.anim-bg-hover {
		@apply transition-colors
		ease-in
		duration-500

		hover:bg-primary-200
		hover:ease-out
		hover:duration-700

		active:bg-primary-100;
	}

	.custom-focus {
		@apply focus-visible:outline
		focus-visible:outline-2
		focus-visible:outline-focus-base
		focus-visible:outline-offset-1;
	}

	.focus-visible {
		@apply focus-visible:outline
		focus-visible:outline-focus-base
		focus-visible:outline-offset-0
		focus-visible:outline-2
		focus-visible:rounded-[3px];
	}

	.plain-text {
		p {
			margin-top: 0.5rem;
		}
	}

	.format-basic-html-tags,
	.markdown-wrapper {
		h1 {
			@apply text-3xl
			mb-6;
		}

		h2 {
			@apply text-2xl
			mb-4;
		}

		h3 {
			@apply text-xl
			mb-4;
		}

		a {
			@apply underline
			text-primary-600

			hover:no-underline;
		}

		p {
			@apply mb-4;
		}

		ul,
		ol {
			@apply mb-4;

			li {
				@apply mb-2;
			}
		}
		& *:last-child {
			@apply mb-0;
		}
	}

	.count-inline {
		@apply inline-flex flex-wrap list-decimal;
		li {
			@apply ml-8 mr-4;
		}
	}

	.truncate {
		@apply whitespace-nowrap
		overflow-hidden
		text-ellipsis;
	}
	/* Needs a defined element width */

	// HOTFIX - remove after DS update to 4.1
	gov-form-label {
		@apply pl-0 #{!important};
	}
}
