.gov-infobar {
	a {
		color: var(--gov-color-neutral-white);
	}
	ul > li::before {
		background-color: var(--gov-color-neutral-white);
	}
}
.gov-bg--warning-400 .gov-infobar {
	a {
		color: var(--gov-color-secondary);
	}
	ul > li::before {
		background-color: var(--gov-color-secondary);
	}
}
