@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
	--gov-container-width: 1238px;
	--gov-layout-content-width: 872px;
	--gov-container-padding-mobile: 16px;
}

/**
	LOAD DESIGN SYSTEM CE
 */
$gov-font-path: '/assets/fonts';
$version: '0.0.1';
@import './assets/fonts/roboto';
@import '@gov-design-system-ce/styles/lib/critical.css';
@import '@gov-design-system-ce/styles/lib/styles.css';
@import '@gov-design-system-ce/styles/lib/layout.css';

@import 'styles/custom/custom';

@import 'styles/data-boxes/ds';

/**
	COMPONENTS STYLES
 */
@import 'styles/document-download/document-download';
@import 'styles/form/autocomplete';
@import 'styles/form/button';
@import 'styles/form/control';
@import 'styles/infobar/infobar';
@import 'styles/modal/modal';
@import 'styles/navigations/main-navigation';
@import 'styles/skeleton/skeleton';
@import 'styles/tables/data-table';
@import 'styles/tables/bin-table';
@import 'styles/sort-arrows/sort-arrows';
@import 'styles/wizard/wizard';
@import 'styles/animations/shake';

/**
	EXCEPTIONS IN STYLES
 */

.scrollbar-fix {
	.body-fixed {
		padding-right: 15px;

		#main-header {
			padding-right: 15px;
		}
	}
}

::-webkit-scrollbar {
	width: 15px;
}
::-webkit-scrollbar-thumb {
	background-color: rgba(0, 0, 0, 0.2);
	border-radius: 8px;
	border: 2px solid transparent;
	background-clip: content-box;
}

a > * {
	color: inherit;
}

.nav-primary--link {
	&:visited,
	&:visited > * {
		color: var(--gov-color-primary-600);
	}
}
h1 strong {
	font-weight: 700;
}

.gov-list--plain {
	li {
		margin-bottom: 0;
	}
}

@layer base {
	main a,
	main .gov-link {
		@apply underline
		text-primary-600

		hover:no-underline;
	}

	ul {
		@apply pl-4;
	}
	ol {
		@apply pl-10;
		li {
			@apply list-decimal;
		}
	}

	.signpost-description p {
		@apply text-s
		md:text-m;
	}
}
