@layer components {
	// TODO: Přidat atribut pro možnost centrování potomků do DS
	.gov-form-group-center {
		.gov-form-group {
			@apply items-center;
		}
	}

	// TODO: Když je přitomen atribut noLabel, tak odebrat/přemístit gov-form-label jako rodiče elementu, teď tam jsou labely dva
	.noLabel {
		.gov-form-checkbox input + span .gov-form-label {
			@apply hidden;
		}
	}

	/* dočasná oprava - odstranit až bude vyřešeno v DS */
	.gov-form-control[type='radio'],
	.gov-form-control[type='checkbox'] {
		.gov-form-control[type='input'],
		.gov-form-control[type='select'],
		.gov-form-control[type='textarea'] {
			.gov-form-control__bottom *[slot='bottom'] {
				margin-left: 0;
			}
		}
	}

	.gov-form-control__bottom {
		.gov-form-message[variant='secondary'] {
			@apply mb-0;
		}
	}
}
