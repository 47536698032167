@layer components {
	.table-data {
		@apply table
			w-full;

		th {
			@apply p-2

			sm:px-4;
		}

		td {
			@apply px-5
			py-2

			sm:px-4
			sm:first-of-type:w-2/5;
		}
	}
	// Striped table
	.table-striped {
		&.striped {
			&-even {
				tbody > tr:nth-of-type(even) {
					@apply bg-secondary-200;
				}
			}

			&-odd {
				tbody > tr:nth-of-type(odd) {
					@apply bg-secondary-200;
				}
			}
		}
	}
	// Responsive table
	.table-responsive thead {
		@apply invisible
		h-0
		absolute

		sm:visible
		sm:h-auto
		sm:relative;
	}

	.table-responsive th {
		@apply px-5;
	}

	.table-responsive tr {
		@apply block

		sm:table-row;
	}

	.table-responsive td {
		@apply block
		w-full
		pt-2
		pb-0

		before:content-[attr(data-label)]
		before:block
		before:text-xs
		before:text-secondary-700

		last:pb-2

		sm:table-cell
		sm:w-auto
		sm:pb-2

		sm:before:content-none;
	}
}
