@layer components {
	.is-visible {
		@apply flex;
	}

	.is-hidden {
		@apply hidden;
	}

	.ds-active {
		@apply border-2
		border-primary-600;
		@apply bg-primary-200 #{!important};
		@apply text-primary-700 #{!important};
	}

	.ds-toggle-name .element {
		@apply w-full
		justify-start;
		@apply p-2 #{!important};

		.gov-chip .element {
			@apply text-s
			min-h-fit;
			@apply py-1 #{!important};
			@apply px-3 #{!important};
		}

		.gov-chip[variant='warning'] .element {
			@apply text-neutral-white;
		}
		.gov-chip[variant='success'] .element {
			@apply text-success-600;
		}
		.gov-chip[variant='error'] .element {
			@apply text-error-500;
		}
	}

	.ds-bottom-nav-item .element {
		@apply w-full
		justify-start;
		@apply py-2 #{!important};
	}

	.ds-no-underline .element {
		@apply no-underline #{!important};
	}

	.ds-checkbox-item input {
		@apply absolute
		top-0
		left-0
		z-10

		w-full
		h-full

		cursor-pointer;
	}

	.ds-new-message .gov-form-control__holder {
		border: inherit;
		@apply relative
		flex
		flex-row
		items-center
		gap-2

		min-h-[48px]
		pb-2

		border-b
		border-secondary-400;

		.gov-form-label {
			@apply w-auto;
		}
		.gov-form-label__label {
			@apply relative
			top-0.5
			m-0

			ds:w-20
			ds:grow-0
			ds:shrink-0;
		}
		.element input,
		.gov-form-control__holder {
			@apply border-0;
		}
		.gov-form-autocomplete__list {
			@apply shadow-s;
		}
		.gov-form-control__bottom {
			@apply hidden;
		}
	}
	.ds-new-message-dropdown {
		position: initial;
		@apply inline-block
		w-full;
		.gov-button {
			@apply w-full;
		}
		.element {
			@apply text-left
			border-0
			w-full
			justify-between

			lg:w-auto
			lg:justify-start;
			@apply hover:bg-primary-200 #{!important};
			> span {
				@apply text-m;
				.gov-icon {
					@apply relative
					top-0.5
					mr-2;
				}
			}
		}

		.gov-dropdown__list {
			@apply left-[-44px]
			right-0
			ds:left-[-88px]
			lg:left-0
			lg:right-auto;
		}
	}

	.ds-new-message {
		.gov-form-autocomplete__list {
			@apply left-[-66px]
			right-[-2px]
			max-h-[400px]
			ds:left-[-88px]
			xxl:left-0
			xxl:max-h-[606px];
		}
	}

	.ds-sender-text {
		.gov-icon {
			@apply relative
				top-0.5
				mr-2;
		}
		strong {
			@apply font-normal;
		}
	}

	.ds-subject {
		.element input {
			@apply text-secondary-800 #{!important};
		}
		.gov-form-control[invalid='true'] {
			.gov-form-control__holder {
				@apply mb-[25px];
				.gov-form-control__bottom {
					@apply block
					absolute
					-bottom-[42px];
				}
			}
		}
	}

	.ds-attachments-dropdown {
		.gov-dropdown__list .element {
			@apply w-full #{!important};
		}
	}

	.ds-bussines-card .gov-dropdown__list {
		@apply left-0 #{!important};
		@apply sm:left-[initial] #{!important};
	}

	.ds-more-info-table .table-data td {
		@apply px-2
		md:px-5;
	}

	.ds-message-footer .gov-button .element {
		@apply border-hidden
		xl:border-solid;
	}
}
