@layer components {
	// Lighter skeleton background
	.skeleton-brighter {
		&.gov-skeleton[variant='secondary'] {
			.gov-skeleton__loader.progress {
				@apply bg-secondary-100
				bg-gradient-to-r from-secondary-100 via-secondary-300 to-secondary-100;
			}
		}
	}

	// To remove the bottom margin in table view
	.table-data {
		.gov-skeleton__loader {
			@apply mb-0;
		}
	}

	// On a darker background, change the skeleton background gradient colours
	.table-striped {
		&.striped {
			&-even {
				tbody > tr:nth-of-type(even) {
					.gov-skeleton[variant='secondary'] {
						.gov-skeleton__loader.progress {
							@apply bg-secondary-400
							bg-gradient-to-r from-secondary-400 via-secondary-100 to-secondary-400;
						}
					}
				}
			}

			&-odd {
				tbody > tr:nth-of-type(odd) {
					.gov-skeleton[variant='secondary'] {
						.gov-skeleton__loader.progress {
							@apply bg-secondary-400
							bg-gradient-to-r from-secondary-400 via-secondary-100 to-secondary-400;
						}
					}
				}
			}
		}
	}
}
