@layer components {
	.button-group .gov-button,
	.button-group .gov-button .element {
		@apply w-full

		md:w-auto;
	}
	.button-fixed-width .element {
		@apply w-full;
		*[slot='right-icon'] {
			@apply ml-auto;
		}
	}

	.button-plain .element {
		@apply items-center;
		@apply no-underline #{!important};
		@apply hover:underline #{!important};
	}

	.button-text-left .element {
		@apply text-left;
	}
}
